import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`10:00 EMOM of:`}</p>
    <p>{`5-Bench Press \\@60% 1RM`}</p>
    <p>{`5-Clapping Pushups`}</p>
    <p>{`then,`}</p>
    <p>{`Death by `}<em parentName="p">{`(increasing by 2 reps each minute)`}</em>{`:`}</p>
    <p>{`Shoulder Press (75/55)(RX+95/65)`}</p>
    <p>{`Calorie Row `}<em parentName="p">{`(you must reset the rower before each round)`}</em></p>
    <p>{`Dips off Bench (legs straight) (RX+ Bar Dips)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This “death by” will alternate movements each minute. So minute
1:00 is 2 shoulder press, minute 2:00 is 2 calorie row, and minute 3:00
is 2 dips. Minute 4:00 is 4 Shoulder Press, minute 5:00 is 4 calorie
row, and minute 6:00 is 4 dips. This continues to increase by 2 reps per
minute. When you fail to get the desired reps on the first movement you
continue on with the other 2 movements. When you fail on the second
movement you continue on with the final movement until failure. Score =
total rounds completed.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      